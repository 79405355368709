.ant-card{
    background-color: #F0F2F5;
}
.card-shadow{
    box-shadow: 0 -3px 31px 0 rgba(0,0,0,.05), 0 6px 20px 0 rgba(0,0,0,.02);
    border-radius: 2px !important;
}
Form{
    background-color: #f7f7f7;
    padding-inline: 2vw !important;
}