body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body{
    background-color: azure;
}

.logo{
    height: 45px;
    width: 45px !important;
}

.menu-item{
    color: white !important;
}
.ant-menu-item-only-child{
    background-color: #2B3A4A  !important;
}

.ant-menu-sub.ant-menu-inline{
    background-color: #2B3A4A !important;
}
.ant-menu-item-selected{
    background-color: #2B3A4A !important;
}
.ant-menu-item{
    color: #ccc !important;
}   
.main-menu:hover svg{
    color: #fff !important;
}
.main-menu:hover .menu-item{
    color: #fff !important;
}
.ant-menu-item-selected svg{
   color: #ccc !important;
}

.menu-item{
    color: #ccc !important;
}
.main-menu:hover svg{
    color: #ccc !;
}

.ant-menu-title-content{
color: #ccc ;
}

.ant-menu-submenu-open {
    background-color: #263442 !important;
}
.submenu:hover .ant-menu-title-content{
    color:  #fff !important;
}

.submenu:hover .ant-menu-submenu-arrow{
    color: #fff !important;
}

.ant-menu-collapse-leave{
    background-color: #263442 !important;
}


.ant-menu-inline .ant-menu-item, .ant-menu-inline .ant-menu-submenu-title {
    width: 100%;
}

.accordion-item{
    border: none;
}

.accordion-button {
    background-color: #2B3A4A  !important;
    color: white;
}
.ant-layout-sider-children {
    background-color: #2B3A4A  !important;
}
/* 
.accordion-button::after {
    color: #fff !important;
    flex-shrink: 0 !important;
    width: 1.25rem !important;
    height: 1.25rem !important;
    margin-left: auto !important;
    content: "" !important;
    background-image: url('/src/Images/accordian-icon.png') !important;
    background-repeat: no-repeat !important;
    background-size: 1.25rem !important;
    transition: transform .2s ease-in-out !important;
    width: 100% !important;
    height: 20px !important;
} */


.accordion-button:focus {
    z-index: 3 !important;
    border:0 !important;
    outline: 0 !important;
    box-shadow: none !important;
}

.accordion-button:not(.collapsed) {
    color: #fff !important;
}

/* .accordion-button:not(.collapsed)::after {
    background-image: url('https://o.remove.bg/downloads/a1f452d1-aa0e-47b3-a898-e5c35ed6c699/png-transparent-arrow-computer-icons-logo-white-down-arrow-miscellaneous-angle-rectangle-thumbnail-removebg-preview.png');
    transform: rotate(-180deg);
} */
/* about section */

.card-shadow{
    box-shadow: 0 -3px 31px 0 rgba(0,0,0,.05), 0 6px 20px 0 rgba(0,0,0,.02);
}

.label-about{
    font-size: medium;
    font-weight: 500;
    
}
input{
    margin: 0;
}

/* end about section */



/* fun fact */
.ant-card-body{
    background: #f7f7f7;

}
.ant-table-row{
    background-color: #f7f7f7;
}


/* fun fact */


/* trustedClientSection */

/* trustedClientSection */



.ant-card{
    background-color: #F0F2F5;
}
.card-shadow{
    box-shadow: 0 -3px 31px 0 rgba(0,0,0,.05), 0 6px 20px 0 rgba(0,0,0,.02);
    border-radius: 2px !important;
}
Form{
    background-color: #f7f7f7;
    padding-inline: 2vw !important;
}
