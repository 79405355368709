/* about section */

.card-shadow{
    box-shadow: 0 -3px 31px 0 rgba(0,0,0,.05), 0 6px 20px 0 rgba(0,0,0,.02);
}

.label-about{
    font-size: medium;
    font-weight: 500;
    
}
input{
    margin: 0;
}

/* end about section */



/* fun fact */
.ant-card-body{
    background: #f7f7f7;

}
.ant-table-row{
    background-color: #f7f7f7;
}


/* fun fact */


/* trustedClientSection */

/* trustedClientSection */


